import "bootstrap/dist/css/bootstrap.min.css";
import "./HomePage.scss";
import { Container, Button, Card, Image } from "react-bootstrap";
import { navigateToApplyPage } from "../../utils/getUserCode.js";
// import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  // const navigate = useNavigate();
  return (
    <>
      <h2 className="text-center mt-3">
        Üdvözlünk a 2024-es Országos Metodista Tábor <br />
        hivatalos oldalán!
      </h2>
      <Container className="homepage-container mt-3 mb-3">
        <Card>
          <Card.Body>
            <Card.Title>Térkép</Card.Title>
            <Card.Text>Helyszín: Kaposvár, Álmos Vezér u. 1.<br/>
            Klebelsberg Középiskolai Kollégium
            </Card.Text>
            <Button
              variant="success"
              size="lg"
              onClick={() => {
                window.open("https://maps.app.goo.gl/Kz17A2EuVC7fpofR6");
              }}
            >
              Megnézem a térképen
            </Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Program</Card.Title>
            <Card.Text>
              Időpont: Július 17-21.<br/>
              Imapercek, úszás, étkezések, dicsőítés, előadások, szabadidő.
            </Card.Text>
            <Button
              variant="success"
              size="lg"
              onClick={() => {
                window.open("https://docs.google.com/spreadsheets/d/1loi4qJgGF4d8sz29FAIPoA66-32CD2jxC3TqxG7OLI4/edit?usp=sharing");
              }}
            >
              Programtábla megnyitása
            </Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Tábor jelentkezés</Card.Title>
            <Card.Text>
              Árak: <a href="./docs/tabor_arak.pdf" target="_blank">LETÖLTÉS</a><br/>
              A jelentkezés lezárult. Jelentkezés módosítása:<br/><a href="mailto:hivatal@metodista.hu" target="_blank">hivatal@metodista.hu</a><br/>emailen vagy a<br/>(00-36) 1 250-1536/32m<br/>telefonszámon kereshetnek.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Bajnokságra jelentkezés</Card.Title>
            <Card.Text>
              Jelentkezz csapatoddal a kedvenc sportágadon belül!
            </Card.Text>
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                // navigate("/championship_apply_page");
                window.open("https://docs.google.com/spreadsheets/d/1ikYCNCgJ6ImrxJJL9ORJvuni1tmZOmsCZtLDTtnjKv4/edit?usp=sharing");
              }}
            >
              Jelentkezés bajnokságra
            </Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Úszásra jelentkezés</Card.Title>
            <Card.Text>Igényfelmérés a reggeli időben való medence használatról.</Card.Text>
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                window.open("https://docs.google.com/spreadsheets/d/17lcAa8gxTqTbfJnfHmabOhqclh_aV4T_1N9c2zvKibw/edit?usp=sharing");
              }}
            >
              Jelentkezés úszásra
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
