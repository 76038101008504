import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.scss";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { HomePage } from "../../pages/Home/HomePage";
import { GalleryPage } from "../../pages/Gallery/GalleryPage";
import { ChampionshipApply } from "../../pages/Championship/ChampionshipApply";
import { ErrorPage } from "../../pages/Error/ErrorPage";
import { Navbar, Nav } from "react-bootstrap";
import { PersonalData } from "../../pages/Apply/PersonalData";
import { Accommodation } from "../../pages/Apply/Accomodation";
import { Participation } from "../../pages/Apply/Participation";
import { Meal } from "../../pages/Apply/Meal";
import { Others } from "../../pages/Apply/Others";
import { Payment } from "../../pages/Apply/Payment";
import { Success } from "../../pages/Apply/Success";
import { navigateToHomePage, navigateToApplyPage } from "../../utils/getUserCode.js";

const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

export const NavigationBar = () => {
  return (
    <Router>
      <Navbar
        expand="lg"
        className="bg-body-tertiary sticky-nav"
        data-bs-theme="dark"
      >
        <Navbar.Brand>Országos Metodista Tábor 2024</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <div className="fake-link" onClick={navigateToHomePage}>
                Főoldal
              </div>
            </Nav.Link>
            <Nav.Link>
              <div className="fake-link">
                Jelentkezés lezárult !!
              </div>
            </Nav.Link>
            <Nav.Link>
              <div className="fake-link" onClick={() => openInNewTab("https://docs.google.com/spreadsheets/d/1loi4qJgGF4d8sz29FAIPoA66-32CD2jxC3TqxG7OLI4/edit?usp=sharing")}>
                Program
              </div>
            </Nav.Link>
            <Nav.Link>
             <div className="fake-link" onClick={() => openInNewTab("https://drive.google.com/drive/folders/1v3OVA75iX0WtMo9nGGnlNkH4QuY9_CGG?usp=drive_link")}>
               Képek a táborról
             </div>
            </Nav.Link>
            <Nav.Link>
             <div className="fake-link" onClick={() => openInNewTab("https://docs.google.com/spreadsheets/d/1ikYCNCgJ6ImrxJJL9ORJvuni1tmZOmsCZtLDTtnjKv4/edit?usp=sharing")}>
               Bajnokság
             </div>
            </Nav.Link>
            <Nav.Link>
             <div className="fake-link" onClick={() => openInNewTab("https://docs.google.com/spreadsheets/d/17lcAa8gxTqTbfJnfHmabOhqclh_aV4T_1N9c2zvKibw/edit?usp=sharing")}>
               Reggeli úszás
             </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery_page" element={<GalleryPage />} />
        <Route
          path="/championship_apply_page"
          element={<ChampionshipApply />}
        />
        <Route path="/apply_page/personal_data" element={<PersonalData />} />
        <Route path="/apply_page/accomodation" element={<Accommodation />} />
        <Route path="/apply_page/participation" element={<Participation />} />
        <Route path="/apply_page/meal" element={<Meal />} />
        <Route path="/apply_page/others" element={<Others />} />
        <Route path="/apply_page/payment" element={<Payment />} />
        <Route path="/apply_page/success" element={<Success />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};
