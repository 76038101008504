import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import "./ApplyPage.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCodeFromUrl } from "../../utils/getUserCode.js";

export const PersonalData = () => {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [personalData, setPersonalData] = useState({
    privacyStatement: false,
    email: "",
    phoneNumber: "",
    name: "",
    district: "",
    address: "",
    birthPlace: "",
    citizenship: "",
    idCard: "",
  });
  
  const districts = [
    { value: "", label: "Válassz körzetet" },
    { value: "obuda", label: "Óbudai körzet" },
    { value: "pest", label: "Pesti körzet" },
    { value: "budakeszi", label: "Budakeszi körzet" },
    { value: "dombovar", label: "Dombóvári körzet" },
    { value: "kaposvar", label: "Kaposvári körzet" },
    { value: "miskolc", label: "Miskolci körzet" },
    { value: "nyiregyhaza", label: "Nyíregyházi körzet" },
    { value: "pecs", label: "Pécsi körzet" },
    { value: "szeged", label: "Szegedi körzet" },
    { value: "szekszard", label: "Szekszárdi körzet" },
    { value: "szolnok", label: "Szolnoki körzet" },
    { value: "kulfold", label: "Küldfölről érkezek" },
  ];
  
  const monthNames = [
    { value: '01', label: "január" },
    { value: '02', label: "február" },
    { value: '03', label: "március" },
    { value: '04', label: "április" },
    { value: '05', label: "május" },
    { value: '06', label: "június" },
    { value: '07', label: "július" },
    { value: '08', label: "augusztus" },
    { value: '09', label: "szeptember" },
    { value: '10', label: "október" },
    { value: '11', label: "november" },
    { value: '12', label: "december" }
  ];

  const [selectedDistrict, setSelectedDistrict] = useState("");
  const handleSelectDistrict = (event) => {
    setSelectedDistrict(event.target.value);
  };
  
  const handleSetBirthDate = (newBirthYear, newBirthMonth, newBirthDay) => {
      const newBirthDate = (newBirthYear < 10 ? "200" + String(newBirthYear)
                          : newBirthYear < 24 ? "20" + String(newBirthYear)
                            : newBirthYear < 100 ? "19" + String(newBirthYear)
                              : String(newBirthYear))
                        + "-"
                        + String(newBirthMonth).padStart(2, "0")
                        + "-"
                        + String(newBirthDay).padStart(2, "0");
      console.log(newBirthDate);
      setBirthYear(newBirthYear);
      setBirthMonth(newBirthMonth);
      setBirthDay(newBirthDay);
      setBirthDate(newBirthDate);
  };
  
  const handleSetBirthYear = (e) => {
      const newBirthYear = e.currentTarget.value;
      handleSetBirthDate(newBirthYear, birthMonth, birthDay);
  };
  
  const handleSetBirthMonth = (e) => {
      const newBirthMonth = e.currentTarget.value;
      handleSetBirthDate(birthYear, newBirthMonth, birthDay);
  };
  const handleSetBirthDay = (e) => {
      const newBirthDay = e.currentTarget.value;
      handleSetBirthDate(birthYear, birthMonth, newBirthDay);
  };
  
  const initInputFields = (urlCode) => {
    axios
      .post("php/client_get_user_data.php", {
        code: urlCode,
      }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response.data);
        setSelectedDistrict(response.data.district);
        setPersonalData({
          privacyStatement: response.data.privacyStatement,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
          name: response.data.name,
          district: response.data.district,
          address: response.data.address,
          birthPlace: response.data.birthPlace,
          citizenship: response.data.citizenship,
          idCard: response.data.idCard,
        });
        
        if (response.data.dateOfBirth !== null) {
            const newBirthYear = response.data.dateOfBirth.substring(0,4);
            const newBirthMonth = response.data.dateOfBirth.substring(5,7);
            const newBirthDay = response.data.dateOfBirth.substring(8,10);
            handleSetBirthDate(newBirthYear, newBirthMonth, newBirthDay);
        }
      });
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("change " + name + " => " + value);
    setPersonalData((prevPersonalData) => ({
      ...prevPersonalData,
      [name]: value,
    }));
  };
  
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    console.log("change " + name + " => " + checked);
    setPersonalData((prevPersonalData) => ({
      ...prevPersonalData,
      [name]: checked,
    }));
  };
  
  const validateEmail = (email) => {
    return (String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null);
  };
  
  const checkForm = () => {
      let errorMessage : string[] = [];
      if (personalData.privacyStatement !== true)
          errorMessage.push("Az adatvédelmi nyilatkozatot el kell fogadni.");
      if (validateEmail(personalData.email) !== true)
          errorMessage.push("Email-cím formátuma hibás.");
      if (personalData.phoneNumber === null || personalData.phoneNumber.length === 0)
          errorMessage.push("Telefonszám nincs megadva.");
      if (personalData.name === null || personalData.name.length === 0)
          errorMessage.push("Név nincs megadva.");
      if (selectedDistrict === null || selectedDistrict.length === 0)
          errorMessage.push("Körzet nincs megadva.");
      if (personalData.address === null || personalData.address.length === 0)
          errorMessage.push("Cím nincs megadva.");
      if (personalData.birthPlace === null || personalData.birthPlace.length === 0)
          errorMessage.push("Születési hely nincs megadva.");
      if (birthDate === null || birthDate.length === 0)
          errorMessage.push("Születési dátum nincs megadva.");
      if (personalData.citizenship === null || personalData.citizenship.length === 0)
          errorMessage.push("Állampolgárság nincs megadva.");
      if (personalData.idCard === null || personalData.idCard.length === 0)
          errorMessage.push("Személyi igazolvány száma nincs megadva.");
    return ({
        valid: (errorMessage.length === 0 ? true : false),
        errorMessage: errorMessage
    });
  };
  
  // Init
  if (!initialized) {   // prevent run the function twice ~ useEffect
    setInitialized(true);
    getCodeFromUrl(function(urlCode) {
      setCode(urlCode);
      initInputFields(urlCode);
    });
  }
  
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>1. Személyes adatok</h1>
          </Col>
        </Row>
        <Row className="firstpage-row2 mb-5">
          <Col>
            <Form.Group className="m-3">
              <Form.Label>
                Email cím <i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                id="email"
                placeholder="példa@email.com"
                value={personalData.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>
                Telefonszám <i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                id="phone-number"
                placeholder="06 30 123 1234"
                value={personalData.phoneNumber}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>
                Teljes név: <i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                id="name"
                placeholder="Gipsz Jakab"
                value={personalData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>
                Lakcím: <i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="text"
                name="address"
                id="address"
                placeholder="1234 Város példa utca 5."
                value={personalData.address}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Select
                value={selectedDistrict}
                onChange={handleSelectDistrict}
              >
                {districts.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>
                Születési hely: <i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="text"
                name="birthPlace"
                id="birth-place"
                placeholder="Város"
                value={personalData.birthPlace}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>
                Születési dátum (év, hónap, nap): <i className="text-danger">*</i>
              </Form.Label>
              <div>
               <input id="dateOfBirthYear" placeholder="év" value={birthYear} className="form-control date-input" type="number" min="1900" max="2024"
                  onChange={handleSetBirthYear}
                  onKeyUp={handleSetBirthYear} />
               <select
                className="form-control date-input"
                value={birthMonth}
                onChange={handleSetBirthMonth}
               >
                {monthNames.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
               </select>
              
               <input id="dateOfBirthDay" value={birthDay} placeholder="nap" className="form-control date-input" type="number" min="1" max="31"
               onChange={handleSetBirthDay}
               onKeyUp={handleSetBirthDay}
                />
              </div>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>
                Állampolgárság: <i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="text"
                name="citizenship"
                id="citizenship"
                placeholder="magyar"
                value={personalData.citizenship}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>
                Személyi igazolvány szám: <i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="text"
                name="idCard"
                id="id-card"
                placeholder="AAAAAA00"
                value={personalData.idCard}
                onChange={handleInputChange}
              />
            </Form.Group>
            
            <Form className="m-3">
              <Form.Check
                type={"checkbox"}
                name={"privacyStatement"}
                id={"privacy-statement"}
                label={
                  <>
                    Elfogadom az <a href="privacy_statement.pdf" target="_blank" rel="noreferrer">Adatkezelési Nyilatkozatot</a> 
                    <i className="text-danger">*</i>
                  </>
                }
                checked={personalData.privacyStatement}
                onChange={handleCheckboxChange}
              />
            </Form>
            
            <i>*Ellenőrizd, hogy minden adatot helyesen írtál-e*</i>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="mb-3"
              onClick={() => {
                  let formCheckResult = checkForm();
                  if (formCheckResult.valid === true) {
                    axios
                      .post("php/client_set_user_data.php", {
                          user_data: {
                            ...personalData,
                            district: selectedDistrict,
                            dateOfBirth: birthDate,
                            code: code
                          },
                          log_event: "PersonalData: Személyes adatok megadása."
                        }, {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                      })
                      .then((response) => {
                        console.log(response.data);
                        navigate("/apply_page/accomodation");
                      });
                  }
                  else {
                    alert("*Ellenőrizd, hogy minden adatot helyesen írtál-e!\n"
                          + formCheckResult.errorMessage.join("\n"));
                  }
              }}
            >
              Következő
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
