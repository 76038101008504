import "bootstrap/dist/css/bootstrap.min.css";
import "./ApplyPage.scss";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { getCodeFromUrl } from "../../utils/getUserCode.js";

export const Payment = () => {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [feeCalcInfo, setFeeCalcInfo] = useState("");
  
  const initInputFields = (urlCode) => {
    axios
      .post("php/client_calculate_fee.php", {
        code: urlCode,
      }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response.data);
        setFeeCalcInfo(response.data.formatted_message);
      });
  };
  
  const finishRegistration = () => {
    console.log("finishRegistration");
    axios
      .post("php/finish_registration.php", {
          code: code
      }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((response) => {
          console.log(response.data);
          if (response.data) {
            navigate("/apply_page/success");
          }
          else {
            alert("Valami hiba történt.");
          }
      });
  };

  // Init
  if (!initialized) {   // prevent run the function twice ~ useEffect
    setInitialized(true);
    getCodeFromUrl(function(urlCode) {
      setCode(urlCode);
      initInputFields(urlCode);
    });
  }
  
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>6. Fizetési információ</h1>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <h4>
              A megadottak alapján a tábor részvételi díja: <br />
            </h4>
            
            <div dangerouslySetInnerHTML={{ __html: feeCalcInfo }}></div>
            
            <h5>
              A tábor részvételi díja befizethető egy összegben vagy 2
              részletben: <br />a teljes összeg első 50%- át legkésőbb
              2024.06.15-ig, második 50%-át legkésőbb 2024.06.30-ig
            </h5>
            <ListGroup className="m-3">
              <ListGroup.Item>Magyarországi Metodista Egyház</ListGroup.Item>
              <ListGroup.Item>10404065-50485348-54531009</ListGroup.Item>
              <ListGroup.Item>
                A közlemény rovatba kérjük beírni: Országos Tábor
              </ListGroup.Item>
            </ListGroup>
            <h5 className="mb-3">
              A jelentkezés csak az előleg beérkezésével válik érvényessé.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => {
                navigate("/apply_page/others");
              }}
            >
              Előző
            </Button>
          </Col>
          <Col>
            <Button
              className="mb-3"
              variant="danger"
              onClick={finishRegistration}
            >
              Jelentkezés beküldése
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
