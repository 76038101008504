import "bootstrap/dist/css/bootstrap.min.css";
import "./ApplyPage.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { getCodeFromUrl } from "../../utils/getUserCode.js";

export const Participation = () => {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [formData, setFormData] = useState({
    lodgeWednesday: false,
    lodgeThursday: false,
    lodgeFriday: false,
    lodgeSaturday: false,
    lodgeSunday: false,
  });

  const [isEntireTimeCheckboxSelected, setEntireTimeCheckbox] = useState(false);

  const handleEntireTimeCheckboxChange = () => {
    setFormData({
      lodgeWednesday: !isEntireTimeCheckboxSelected,
      lodgeThursday: !isEntireTimeCheckboxSelected,
      lodgeFriday: !isEntireTimeCheckboxSelected,
      lodgeSaturday: !isEntireTimeCheckboxSelected,
      lodgeSunday: !isEntireTimeCheckboxSelected,
    });
    setEntireTimeCheckbox(!isEntireTimeCheckboxSelected);
  };
  
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
    if (
      newFormData.lodgeWednesday &&
      newFormData.lodgeWednesday &&
      newFormData.lodgeThursday &&
      newFormData.lodgeFriday &&
      newFormData.lodgeSaturday &&
      newFormData.lodgeSunday
    ) {
      setEntireTimeCheckbox(true);
    } else if (
      !newFormData.lodgeWednesday &&
      !newFormData.lodgeWednesday &&
      !newFormData.lodgeThursday &&
      !newFormData.lodgeFriday &&
      !newFormData.lodgeSaturday &&
      !newFormData.lodgeSunday
    ) {
      setEntireTimeCheckbox(false);
    }
  };
  
  const initInputFields = (urlCode) => {
    axios
    .post("php/client_get_user_data.php", {
        code: urlCode,
      }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
      console.log(response.data);
      handleSetFormData({
        code: response.data.code,
        lodgeWednesday: response.data.lodgeWednesday,
        lodgeThursday: response.data.lodgeThursday,
        lodgeFriday: response.data.lodgeFriday,
        lodgeSaturday: response.data.lodgeSaturday,
        lodgeSunday: response.data.lodgeSunday,
      });
    });
  };
  
  // Init
  if (!initialized) {   // prevent run the function twice ~ useEffect
    setInitialized(true);
    getCodeFromUrl(function(urlCode) {
      setCode(urlCode);
      initInputFields(urlCode);
    });
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>3. Részvétel a táborban</h1>
          </Col>
        </Row>
        <Row className="mb-5">
          <Form>
            <Form.Check
              type={"checkbox"}
              id={`entire-time`}
              className="m-1"
              label={"Végig itt leszek a táborban"}
              checked={isEntireTimeCheckboxSelected}
              onChange={() => handleEntireTimeCheckboxChange()}
            />
            <Form.Check
              type={"checkbox"}
              id={`wednesday`}
              className="m-1"
              label={"Szerda"}
              checked={formData.lodgeWednesday}
              onChange={() => {
                setEntireTimeCheckbox(false);
                handleSetFormData({
                  ...formData,
                  lodgeWednesday: !formData.lodgeWednesday,
                });
              }}
            />
            <Form.Check
              type={"checkbox"}
              id={`thursday`}
              className="m-1"
              label={"Csütörtök"}
              checked={formData.lodgeThursday}
              onChange={() => {
                setEntireTimeCheckbox(false);
                handleSetFormData({
                  ...formData,
                  lodgeThursday: !formData.lodgeThursday,
                });
              }}
            />
            <Form.Check
              type={"checkbox"}
              id={`friday`}
              className="m-1"
              label={"Péntek"}
              checked={formData.lodgeFriday}
              onChange={() => {
                setEntireTimeCheckbox(false);
                handleSetFormData({
                  ...formData,
                  lodgeFriday: !formData.lodgeFriday,
                });
              }}
            />
            <Form.Check
              type={"checkbox"}
              id={`saturday`}
              className="m-1"
              label={"Szombat"}
              checked={formData.lodgeSaturday}
              onChange={() => {
                setEntireTimeCheckbox(false);
                handleSetFormData({
                  ...formData,
                  lodgeSaturday: !formData.lodgeSaturday,
                });
              }}
            />
            <Form.Check
              type={"checkbox"}
              id={`sunday`}
              className="m-1"
              label={"Vasárnap"}
              checked={formData.lodgeSunday}
              onChange={() => {
                setEntireTimeCheckbox(false);
                handleSetFormData({
                  ...formData,
                  lodgeSunday: !formData.lodgeSunday,
                });
              }}
            />
          </Form>
        </Row>
        <Row>
          <Col>
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => {
                navigate("/apply_page/accomodation");
              }}
            >
              Előző
            </Button>
          </Col>
          <Col>
            <Button
              className="mb-3"
              onClick={() => {
                axios.post("php/client_set_user_data.php", {
                  user_data: { ...formData, code: code },
                  log_event: "Participation: Táborban töltött napok kitöltése."
                }, {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                })
                .then((response) => {
                  navigate("/apply_page/meal");
                });
              }}
            >
              Következő
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
