
export const GalleryPage = () => {
  return (
    <>
      <div className="gallery-page">
        GalleryPage
      </div>
    </>
  );
};
