import "bootstrap/dist/css/bootstrap.min.css";
import { SetStateAction, useState } from "react";
import "./ApplyPage.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCodeFromUrl } from "../../utils/getUserCode.js";

export const Accommodation = () => {
  const [initialized, setInitialized] = useState(false);
  const [code, setCode] = useState("");
  const [roomType, setRoomType] = useState("kollégiumiSzoba");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (event: { currentTarget: { value: SetStateAction<string>; }; }) => {
    setRoomType(event.currentTarget.value);
  };
  
  const initInputFields = (urlCode) => {
    axios
      .post("php/client_get_user_data.php", {
          code: urlCode,
        }, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        setMessage(response.data.message);
        setRoomType((response.data.roomType || "kollégiumiSzoba"));
      });
  };
  
  // Init
  if (!initialized) {   // prevent run the function twice ~ useEffect
    setInitialized(true);
    getCodeFromUrl(function(urlCode) {
      setCode(urlCode);
      initInputFields(urlCode);
    });
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>2. Szállás</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Check
                name="room-type"
                type={"radio"}
                label={"Kollégiumi szoba"}
                id={"dorm-room"}
                value={"kollégiumiSzoba"}
                checked={roomType === "kollégiumiSzoba"}
                onChange={handleChange}
              />
              <i className="text-secondary">
                4 ágyas, 2 szobánként közös fürdő
              </i>
              <Form.Group
                className="mt-3 mb-3"
                style={{
                  display: roomType === "kollégiumiSzoba" ? "block" : "none",
                }}
              >
                <Form.Control
                  as="textarea"
                  placeholder="Pl.: A családommal szeretnék egy szobában lenni."
                  rows={4}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.currentTarget.value);
                  }}
                />
              </Form.Group>
              <Form.Check
                className="mt-3 mb-5"
                name="room-type"
                type={"radio"}
                label={"Külső szállás"}
                id={"external-room"}
                value={"külsőSzállás"}
                checked={roomType === "külsőSzállás"}
                onChange={handleChange}
              />
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => {
                navigate("/apply_page/personal_data");
              }}
            >
              Előző
            </Button>
          </Col>
          <Col>
            <Button
              className="mb-3"
              onClick={() => {
                axios.post("php/client_set_user_data.php", {
                  user_data: {
                      code: code,
                      roomType: roomType,
                      message: message,
                  },
                  log_event: "Accomodation: Szálláshely kiválasztása."
                }, {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                })
                .then((response) => {
                  navigate("/apply_page/participation");
                });
              }}
            >
              Következő
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
