import axios from "axios";

export const checkUserCode = (code, callback) => {
  axios.post("php/check_code.php", {
    code: code,
  }, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then((response) => {
     console.log(response);
     if (typeof callback == "function") callback(response.data);
  });
};

export const addNewUser = (log_event, callback) => {
    axios.post("php/add_new_user.php", {
      log_event: log_event
    }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
       console.log(response.data);
       console.log("Added new user with code " + response.data.code);
       if (typeof callback == "function") callback(response.data.code);
    });
};

export const addNewGroupMember = (code, log_event, callback) => {
    axios.post("php/add_new_user.php", {
      parent_code: code,
      log_event: log_event
    }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
       console.log(response.data);
       console.log("Added new group member with code " + response.data.code);
       if (typeof callback == "function") callback(response.data.code);
    });
};

export const getUserCode = (callback) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const urlCode = queryParameters.get("code");
  checkUserCode(urlCode, function(data) {
     if (data === 0) {
        addNewUser("getUserCode: Nincs megadva kód --> új felhasználó hozzáadása.", callback);
     }
     else {
       console.log("User already logged in with code " + urlCode);
       if (typeof callback == "function") callback(urlCode);
     }
  });
};

export const navigateToApplyPage = () => {  // code given in URL
  getUserCode(function(validCode) {
      navigateToApplyPageWithCode(validCode);
  });
};

export const navigateToApplyPageWithCode = (code) => {   // generate new code
    window.location.href = window.location.origin
                         + window.location.pathname
                         + "?code="
                         + code
                         + "#/apply_page/personal_data";
};

export const navigateToHomePage = () => {
    window.location.href = window.location.origin
                         + window.location.pathname;
};

export const getCodeFromUrl = (callback) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const urlCode = (queryParameters.get("code") || "");
  
  console.log("read url code: " + urlCode);
  checkUserCode(urlCode, function(valid) {
      if (valid) {
        callback(urlCode);
      }
      else {
        navigateToHomePage();
      }
  });
};
