import "bootstrap/dist/css/bootstrap.min.css";
import "./ApplyPage.scss";
import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { getCodeFromUrl, 
         addNewUser, addNewGroupMember,
         navigateToHomePage, navigateToApplyPageWithCode
       } from "../../utils/getUserCode.js";

export const Success = () => {
  const [initialized, setInitialized] = useState(false);
  const [code, setCode] = useState("");
  const initInputFields = (urlCode) => {
    console.log(code + " == " + urlCode);
    // TODO ?
  };
  
  // Init
  if (!initialized) {   // prevent run the function twice ~ useEffect
    setInitialized(true);
    getCodeFromUrl(function(urlCode) {
      setCode(urlCode);
      initInputFields(urlCode);
    });
  }
  
  const startNewGroupMemberRegistration = () => {
     addNewGroupMember(code, "Success: Új csoporttag jelentkezése", navigateToApplyPageWithCode);
  };
  
  const startNewRegistration = () => {
      addNewUser("Success: Új jelentkezés beküldése", navigateToApplyPageWithCode);
  };
  
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>7. Visszaigazolás a sikeres regisztrációról.</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              Köszönjük a jelentkezést! Visszaigazoló emailt küldtünk a megadott
              címre.
            </h4>
          </Col>
        </Row>
        <Row style={{marginTop: "20px"}}>
          <Col style={{textAlign: "right"}}>
            <Button
              className="mb-3"
              variant="secondary"
              onClick={startNewRegistration}
            >
              Új jelentkezés beküldése
            </Button>
          </Col>
          <Col style={{textAlign: "center"}}>
            <Button
              className="mb-3"
              variant="success"
              onClick={startNewGroupMemberRegistration}
            >
              Új csoporttag (családtag) jelentkezése
            </Button>
          </Col>
          <Col style={{textAlign: "left"}}>
            <Button
              className="mb-3"
              variant="info"
              onClick={navigateToHomePage}
            >
              Vissza a kezdőlapra
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
