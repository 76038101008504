import "bootstrap/dist/css/bootstrap.min.css";
import "./ApplyPage.scss";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { getCodeFromUrl } from "../../utils/getUserCode.js";

export const Others = () => {
  const [initialized, setInitialized] = useState(false);
  const [enableSupport, setEnableSupport] = useState(false);
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [formData, setFormData] = useState({
    comment: "",
    needSupport: false,
    offerSupport: false,
    supportAmount: "",
    comingByCar: false,
  });
  
  const initInputFields = (urlCode) => {
    axios
      .post("php/client_get_user_data.php", {
        code: urlCode,
      }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response.data);
        const supportEnabled = (
            response.data.roomType === "kollégiumiSzoba" &&
            response.data.lodgeWednesday === true &&
            response.data.lodgeThursday === true &&
            response.data.lodgeFriday === true &&
            response.data.lodgeSaturday === true
        );
        
        setEnableSupport(supportEnabled);
        setFormData({
          comment: response.data.comment,
          needSupport: (response.data.needSupport && supportEnabled),
          offerSupport: response.data.offerSupport,
          supportAmount: response.data.supportAmount,
          comingByCar: response.data.comingByCar,
        });
      });
  };
  
  // Init
  if (!initialized) {   // prevent run the function twice ~ useEffect
    setInitialized(true);
    getCodeFromUrl(function(urlCode) {
      setCode(urlCode);
      initInputFields(urlCode);
    });
  }
  
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>5. Egyéb</h1>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <Form>
              <h4>Észrevétel, megjegyzés, ötlet.</h4>
              <Form.Control
                as="textarea"
                rows={4}
                className="mb-3"
                value={formData.comment}
                onChange={(e) => {
                  setFormData({ ...formData, comment: e.currentTarget.value });
                }}
              />
              <Form.Check
                className="mt-2"
                type={"checkbox"}
                id={`need-support`}
                label={"Támogatást kérek (20.000 Ft, ha 4 éjszakát a táborban száll meg)"}
                disabled={!enableSupport}
                checked={formData.needSupport}
                onChange={() => {
                  setFormData({
                    ...formData,
                    needSupport: !formData.needSupport,
                  });
                }}
              />
              <Form.Check
                type={"checkbox"}
                id={`coming-by-car`}
                label={"Autóval jövök, van még szabad hely."}
                checked={formData.comingByCar}
                onChange={() => {
                  setFormData({
                    ...formData,
                    comingByCar: !formData.comingByCar,
                  });
                }}
              />
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => {
                navigate("/apply_page/meal");
              }}
            >
              Előző
            </Button>
          </Col>
          <Col>
            <Button
              className="mb-3"
              onClick={() => {
                axios
                  .post("php/client_set_user_data.php", {
                      user_data: {...formData, code: code},
                      log_event: "Others: Egyéb adatok kitöltése."
                    }, {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                  })
                  .then((response) => {
                    navigate("/apply_page/payment");
                  });
              }}
            >
              Következő
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
