import "bootstrap/dist/css/bootstrap.min.css";
import "./ApplyPage.scss";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { getCodeFromUrl } from "../../utils/getUserCode.js";

export const Meal = () => {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const [age, setAge] = useState(0);
  const [code, setCode] = useState("");
  const [whichDays, setWhichDays] = useState({
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [meal, setMeal] = useState({
    childsPortion: false,
    specialMeal: "",
    foodWednesdayDinner: false,
    foodThursdayBreakfast: false,
    foodThursdayLunch: false,
    foodThursdayDinner: false,
    foodFridayBreakfast: false,
    foodFridayLunch: false,
    foodFridayDinner: false,
    foodSaturdayBreakfast: false,
    foodSaturdayLunch: false,
    foodSaturdayDinner: false,
    foodSundayBreakfast: false,
    foodSundayLunch: false,
  });

  const [isEntireTimeCheckboxSelected, setEntireTimeCheckbox] = useState(false);

  const handleEntireTimeCheckboxChange = () => {
    // HA ki lesz választva a 'minden' opció (!isEntireTimeCheckboxSelected)
    // ÉS az adott napot a táborban tölti (whichDays.day)
    // AKKOR kipipáljuk, egyébként levesszük a pipát.
    setMeal({
        ...meal,
      foodWednesdayDinner   : (!isEntireTimeCheckboxSelected && whichDays.wednesday),
      foodThursdayBreakfast : (!isEntireTimeCheckboxSelected && whichDays.thursday),
      foodThursdayLunch     : (!isEntireTimeCheckboxSelected && whichDays.thursday),
      foodThursdayDinner    : (!isEntireTimeCheckboxSelected && whichDays.thursday),
      foodFridayBreakfast   : (!isEntireTimeCheckboxSelected && whichDays.friday),
      foodFridayLunch       : (!isEntireTimeCheckboxSelected && whichDays.friday),
      foodFridayDinner      : (!isEntireTimeCheckboxSelected && whichDays.friday),
      foodSaturdayBreakfast : (!isEntireTimeCheckboxSelected && whichDays.saturday),
      foodSaturdayLunch     : (!isEntireTimeCheckboxSelected && whichDays.saturday),
      foodSaturdayDinner    : (!isEntireTimeCheckboxSelected && whichDays.saturday),
      foodSundayBreakfast   : (!isEntireTimeCheckboxSelected && whichDays.sunday),
      foodSundayLunch       : (!isEntireTimeCheckboxSelected && whichDays.sunday),
    });
    
    setEntireTimeCheckbox(!isEntireTimeCheckboxSelected);
  };

  const handleSetFormData = (newFormData) => {
    console.log("handleSetFormData:");
    console.log(newFormData);
    // 1. HA egyik napot sem tölti a táborban,
    //    AKKOR leveszi a pipát a 'minden' opcióról.
    // 2. HA egy napot nem a táborban tölt,
    //    AKKOR az aznapi étkezés beállítása nem számít.
    // 3. HA egy napot a táborban tölt,
    //    ÉS nem kéri valamelyik aznapi ételt,
    //    AKKOR leveszi a pipát a 'minden' opcióról.
    // 4. HA egy napot a táborban tölt,
    //    ÉS kéri az összes aznapi étkezést,
    //    ÉS ez minden táborban töltött napra teljesül,
    //    AKKOR kipipálja a 'minden' opciót.
    setMeal(newFormData);
    const toSetEntireTimeCheckbox = (
      // 1.:
      (whichDays.wednesday ||
       whichDays.thursday  ||
       whichDays.friday    ||
       whichDays.saturday  ||
       whichDays.sunday) &&
      // 2:                    3, 4.:
      (!whichDays.wednesday || newFormData.foodWednesdayDinner)   &&
      (!whichDays.thursday  || newFormData.foodThursdayBreakfast) &&
      (!whichDays.thursday  || newFormData.foodThursdayLunch)     &&
      (!whichDays.thursday  || newFormData.foodThursdayDinner)    &&
      (!whichDays.friday    || newFormData.foodFridayBreakfast)   &&
      (!whichDays.friday    || newFormData.foodFridayLunch)       &&
      (!whichDays.friday    || newFormData.foodFridayDinner)      &&
      (!whichDays.saturday  || newFormData.foodSaturdayBreakfast) &&
      (!whichDays.saturday  || newFormData.foodSaturdayLunch)     &&
      (!whichDays.saturday  || newFormData.foodSaturdayDinner)    &&
      (!whichDays.sunday    || newFormData.foodSundayBreakfast)   &&
      (!whichDays.sunday    || newFormData.foodSundayLunch));
    
    setEntireTimeCheckbox(toSetEntireTimeCheckbox);
  };
  
  const initInputFields = (urlCode) => {
    axios
      .post("php/client_get_user_data.php", {
        code: urlCode,
      }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // init form data:
        console.log(response.data);
        
        // init which days are spent in camp:
        setWhichDays({
          wednesday: response.data.lodgeWednesday,
          thursday: response.data.lodgeThursday,
          friday: response.data.lodgeFriday,
          saturday: response.data.lodgeSaturday,
          sunday: response.data.lodgeSunday,
        });
        
        // init checkboxes:
        handleSetFormData({
          code: response.data.code,
          childsPortion: response.data.childsPortion,
          specialMeal: response.data.specialMeal,
          foodWednesdayDinner: response.data.foodWednesdayDinner,
          foodThursdayBreakfast: response.data.foodThursdayBreakfast,
          foodThursdayLunch: response.data.foodThursdayLunch,
          foodThursdayDinner: response.data.foodThursdayDinner,
          foodFridayBreakfast: response.data.foodFridayBreakfast,
          foodFridayLunch: response.data.foodFridayLunch,
          foodFridayDinner: response.data.foodFridayDinner,
          foodSaturdayBreakfast: response.data.foodSaturdayBreakfast,
          foodSaturdayLunch: response.data.foodSaturdayLunch,
          foodSaturdayDinner: response.data.foodSaturdayDinner,
          foodSundayBreakfast: response.data.foodSundayBreakfast,
          foodSundayLunch: response.data.foodSundayLunch,
        });
        
        // calculate age:
        let age = 0;
        if (response.data.dateOfBirth !== null) {
            age = (2024 
                - Number(response.data.dateOfBirth.substring(0, 4))
                - ("0717" < (response.data.dateOfBirth.substring(5, 7)
                           + response.data.dateOfBirth.substring(8, 10))
                           ? 1 : 0));
            setAge(age);
        }
      });
  };
  
  if (!initialized) {   // prevent run the function twice ~ useEffect
    setInitialized(true);
    getCodeFromUrl(function(urlCode) {
      setCode(urlCode);
      initInputFields(urlCode);
    });
  }
  
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>4. Étkezés</h1>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <Form>
              <Form.Check
                type={"checkbox"}
                id={`every-day`}
                label={"Minden táborban töltött napra kérek étkezést."}
                checked={isEntireTimeCheckboxSelected}
                onChange={() => {
                  handleEntireTimeCheckboxChange();
                }}
              />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Reggeli</th>
                    <th>Ebéd</th>
                    <th>Vacsora</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Szerda</td>
                    <td>#</td>
                    <td>#</td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`wednesday-dinner`}
                        checked={meal.foodWednesdayDinner}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodWednesdayDinner: !meal.foodWednesdayDinner,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Csütörtök</td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`thursday-breakfast`}
                        checked={meal.foodThursdayBreakfast}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodThursdayBreakfast: !meal.foodThursdayBreakfast,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`thursday-lunch`}
                        checked={meal.foodThursdayLunch}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodThursdayLunch: !meal.foodThursdayLunch,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`thursday-dinner`}
                        checked={meal.foodThursdayDinner}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodThursdayDinner: !meal.foodThursdayDinner,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Péntek</td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`friday-breakfast`}
                        checked={meal.foodFridayBreakfast}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodFridayBreakfast: !meal.foodFridayBreakfast,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`friday-lunch`}
                        checked={meal.foodFridayLunch}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodFridayLunch: !meal.foodFridayLunch,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`friday-dinner`}
                        checked={meal.foodFridayDinner}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodFridayDinner: !meal.foodFridayDinner,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Szombat</td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`saturday-breakfast`}
                        checked={meal.foodSaturdayBreakfast}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodSaturdayBreakfast: !meal.foodSaturdayBreakfast,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`saturday-lunch`}
                        checked={meal.foodSaturdayLunch}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodSaturdayLunch: !meal.foodSaturdayLunch,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`saturday-dinner`}
                        checked={meal.foodSaturdayDinner}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodSaturdayDinner: !meal.foodSaturdayDinner,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Vasárnap</td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`sunday-breakfast`}
                        checked={meal.foodSundayBreakfast}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodSundayBreakfast: !meal.foodSundayBreakfast,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"checkbox"}
                        id={`sunday-lunch`}
                        checked={meal.foodSundayLunch}
                        onChange={() => {
                          handleSetFormData({
                            ...meal,
                            foodSundayLunch: !meal.foodSundayLunch,
                          });
                        }}
                      />
                    </td>
                    <td>#</td>
                  </tr>
                </tbody>
              </Table>
              <i className="text-secondary">
                Reggeli 1900 Ft, ebéd 2200 Ft, vacsora 2000 Ft
              </i>
              <div>
                <Form.Check
                  className="mt-2"
                  type={"checkbox"}
                  id={`childs-portion`}
                  label={"Gyerekadagot kérek"}
                  disabled={age >= 18 ? true : false}
                  checked={meal.childsPortion}
                  onChange={() => {
                    setMeal({
                      ...meal,
                      childsPortion: !meal.childsPortion,
                    });
                  }}
                />
                <i className="text-secondary">
                  18 év alatti gyermeknek 50% kedvezménnyel.
                </i>
              </div>
              <h4 className="mt-2">
                Speciális étkezés / ételérzékenység / diéta jelzése
              </h4>
              <Form.Control
                style={{ width: "50vw" }}
                as="textarea"
                id="special-meal"
                placeholder="Pl.: gluténérzékeny,
                laktózérzékeny, IR / cukorbeteg"
                rows={4}
                className="mb-3"
                value={meal.specialMeal}
                onChange={(e) => {
                  setMeal({
                    ...meal,
                    specialMeal: e.currentTarget.value,
                  });
                }}
              />
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => {
                navigate("/apply_page/participation");
              }}
            >
              Előző
            </Button>
          </Col>
          <Col>
            <Button
              className="mb-3"
              onClick={() => {
                axios.post("php/client_set_user_data.php", {
                  user_data: { ...meal, code: code },
                  log_event: "Meal: étkezés kiválasztása",
                }, {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  }
                })
                .then((response) => {
                  navigate("/apply_page/others");
                });
              }}
            >
              Következő
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
